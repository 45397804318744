import { createSlice } from "@reduxjs/toolkit";
import * as apiService from "../services/api-service";
import { toast } from "react-toastify";

const initialState = {
  eventData: null,
  eventFullData: null,
  history: [],
  planData: null,
  eventRatings: [],
  shareLinks: [],
  isDeleteTreeconfirmModalOpen: false,
  userDataLoading: false,
  userData: null,
  selectedTreeId: "",
  selectedDeleteTreeId: "",
  selectedDeleteTreeName: "",
  selectedUpdateTreeId: "",
  loading: false,
  plansLoading: false,
  planGenerating: false,
  eventGenerating: false,
  KPIsGenerating: false,
  visualizationType: 1,
  isTreeHistoryLoading: false,
  visibleRightPanel: "TreeHistory",
  totalPages: 1,
  selectedTreeNode: null,
  deleteConfirmationModalType: "",
  selectedTreePlannedNodeNames: [],
  lastCompletedStep: 0,
  contextStepInfo: null,
  treeStepInfo: null,
  importantNodes: [],
  KPIs: null,
};

const isMobile = window.innerWidth < window.innerHeight

// Slice
const treeMapSlice = createSlice({
  name: "user",
  initialState,
  reducers: {
    reset: (state) => {
      return initialState;
    },
    setEventData: (state, action) => {
      state.eventData = action.payload;
    },
    setEventFullData: (state, action) => {
      state.eventFullData = action.payload;
    },
    setHistory: (state, action) => {
      state.history = action.payload;
    },
    setHistoryLoading: (state, action) => {
      state.isTreeHistoryLoading = action.payload;
    },
    setPlanData: (state, action) => {
      state.planData = action.payload;
    },
    setEventRatings: (state, action) => {
      state.eventRatings = action.payload
    },
    setSelectedUpdateTreeId: (state, action) => {
      state.selectedUpdateTreeId = action.payload;
    },
    setIsConfirmDeleteModalOpen: (state, action) => {
      state.isDeleteTreeconfirmModalOpen = !state.isDeleteTreeconfirmModalOpen;
      state.deleteConfirmationModalType = action.payload?.type;
      state.selectedDeleteTreeId = action.payload?.id;
      state.selectedDeleteTreeName = action.payload?.name;
    },
    setSelectedTreeId: (state, action) => {
      state.selectedTreeId = action.payload;
    },
    setVisibleRightPanel: (state, action) => {
      state.visibleRightPanel = action.payload;
    },
    setPlanLoading: (state, action) => {
      state.plansLoading = action.payload;
    },
    setPlanGenerating: (state, action) => {
      state.planGenerating = action.payload;
    },
    setEventGenerating: (state, action) => {
      state.eventGenerating = action.payload;
    },
    setKPIsGenerating: (state, action) => {
      state.KPIsGenerating = action.payload;
    },
    setVisualizationType: (state, action) => {
      state.visualizationType = action.payload;
    },
    setUserDataLoading: (state, action) => {
      state.userDataLoading = action.payload;
    },
    setUserData: (state, action) => {
      state.userData = action.payload;
    },
    setSelectedTreePlannedNodeNames: (state, action) => {
      state.selectedTreePlannedNodeNames = action.payload;
    },
    setSelectedTreeNode: (state, action) => {
      state.selectedTreeNode = action.payload;
    },
    setLoading: (state, action) => {
      state.loading = action.payload
    },
    setTotalPages: (state, action) => {
      state.totalPages = action.payload
    },
    setShareLinks: (state, action) => {
      state.shareLinks = action.payload
    },
    setLastCompletedStep: (state, action) => {
      state.lastCompletedStep = action.payload
    },
    setContextStepInfo: (state, action) => {
      state.contextStepInfo = action.payload
    },
    setTreeStepInfo: (state, action) => {
      state.treeStepInfo = action.payload
    },
    setImportantNodes: (state, action) => {
      state.importantNodes = action.payload
    },
    setKPIs: (state, action) => {
      state.KPIs = action.payload
    }
  },
});

// Actions
export const {
  reset,
  setEventData,
  setEventFullData,
  setHistory,
  setIsConfirmDeleteModalOpen,
  setSelectedTreeId,
  setSelectedUpdateTreeId,
  setHistoryLoading,
  setTreeHistoryLoading,
  setVisibleRightPanel,
  setPlanLoading,
  setPlanGenerating,
  setEventGenerating,
  setKPIsGenerating,
  setVisualizationType,
  setPlanData,
  setEventRatings,
  setUserDataLoading,
  setUserData,
  setSelectedTreePlannedNodeNames,
  setSelectedTreeNode,
  setLoading,
  setTotalPages,
  setShareLinks,
  setLastCompletedStep,
  setContextStepInfo,
  setTreeStepInfo,
  setImportantNodes,
  setKPIs
} = treeMapSlice.actions;
export default treeMapSlice.reducer;

export const generateEvent = ({ key, event, job_title, context, requestType, main_branches }) => async (dispatch) => {
  dispatch(setEventGenerating(true));
  dispatch(setLoading(true));
  try {
    const requestBody = {
      title: requestType === 1 ? job_title : event,
      context,
      main_branches,
      ...(requestType === 1 ? { employee_name: event, job_title } : { event_name: event }),
      ...(!key && { requestType })
    };
    await key ? apiService.editEvent({ key, requestBody }) : apiService.createEvent(requestBody);
  } catch (err) {
    dispatch(setEventData(null));
    notify(err?.message, "error");
  } finally {
    dispatch(setLoading(false));
    dispatch(setEventGenerating(false));
  }
};

export const generateNewBranch = ({ key, context }) => async (dispatch) => {
  dispatch(setEventGenerating(true));
  try {
    await apiService.generateNewBranch({ key, context })
  } catch (err) {
    dispatch(setEventGenerating(false));
    notify(err?.message, "error");
  } finally {
    dispatch(setEventGenerating(false));
  }
};

export const getEvent = (key) => async (dispatch, getState) => {
  return new Promise((resolve, reject) => {
    const historyData = getState().treeMap.history;
    if (historyData && getState().treeMap.selectedTreeId !== key) {
      dispatch(setLoading(true));
      dispatch(setSelectedTreeId(key));
      apiService.getEvent(key)
        .then((response) => {
          if (response?.error) {
            notify(response.error.code, "error");
            dispatch(setEventData(null));
            dispatch(setEventFullData(null));
            // reject();
          } else if (response.status === 200) {
            dispatch(getEventInfo(key))
            dispatch(setSelectedTreePlannedNodeNames(response.data?.event?.plans?.map((el) => el.node_name)));
            dispatch(setEventData(response.data?.event?.node));
            dispatch(setImportantNodes(response.data?.event?.important_nodes));
            dispatch(setEventFullData(response.data?.event));
            dispatch(setShareLinks(response?.data?.event?.self_assessment_labels))
          }
          resolve();
        })
        .catch((error) => {
          dispatch(setEventData(null));
          dispatch(setEventFullData(null));
          dispatch(setLoading(false));
          // console.log("ERROR MESSAGE", error);
          notify(error.message, "error");
        })
    }
  });
};

export const getEventInfo = (eventId) => async (dispatch) => {
  return new Promise((resolve, reject) => {
    apiService.getEventInfo(eventId)
      .then((response) => {
        if (response?.error) {
          notify("Something went wrong!", "error");
          dispatch(setEventRatings([]));
          // reject();
        } else if (response.status === 200) {
          dispatch(setEventRatings(response.data?.result));
          dispatch(setLoading(false));
        }
        resolve();
      })
      .catch((error) => {
        dispatch(setEventRatings(null));
        dispatch(setLoading(false));
        notify(error.message, "error");
      })
  });
};

export const rateAndNote = ({ node_name, note, rate }) => async (dispatch, getState) => {
  return new Promise((resolve, reject) => {
    const eventId = getState().treeMap.selectedTreeId;
    const selectedEventRatings = getState().treeMap.eventRatings;
    apiService.rateAndNote({ node_name, eventId, note, rate })
      .then((response) => {
        if (response?.error) {
          notify("Something went wrong!", "error");
          // reject();
        } else if (response.status === 201) {
          dispatch(setEventRatings([...selectedEventRatings, response.data?.result]));
        }
        resolve();
      })
      .catch((error) => {
        // console.log("ERROR MESSAGE", error);
        notify(error?.message, "error");
      })
  });
};

export const editNodeInfo = ({ nodeId, note, rate }) => async (dispatch, getState) => {
  return new Promise((resolve, reject) => {
    const selectedEventRatings = getState().treeMap.eventRatings,
      eventId = getState().treeMap.selectedTreeId
    apiService.editNodeInfo({ eventId, nodeId, note, rate })
      .then((response) => {
        if (response?.error) {
          notify("Something went wrong!", "error");
          // reject();
        } else if (response.status === 200) {
          dispatch(setEventRatings(selectedEventRatings.map(el => el._id == nodeId ? { ...el, rate, note } : el)));
        }
        resolve();
      })
      .catch((error) => {
        // console.log("ERROR MESSAGE", error);
        notify(error?.message, "error");
      })
  });
};

export const deleteBranch = (node_name) => async (dispatch, getState) => {
  return new Promise((resolve, reject) => {
    const selectedEventId = getState().treeMap.selectedTreeId,
      currentEventData = getState().treeMap.eventData;
    function removeNodeByName(node, name) {
      let nodeData = node.Pitfalls ? { name: "event name", children: node.Pitfalls } : node
      return {
        ...nodeData,
        children: nodeData.children?.reduce((acc, child) => {
          const updatedChild = removeNodeByName(child, name);
          if (updatedChild.name === name) {
            updatedChild.children?.forEach(innerChild => {
              innerChild.children?.forEach(el => document.getElementById(el.name).style.opacity = 0);
              document.getElementById(innerChild.name).style.opacity = 0;
            });
            document.getElementById(updatedChild.name).style.opacity = 0;
          } else acc.push(updatedChild);
          return acc;
        }, [])
      };
    }
    apiService.deleteBranch(selectedEventId, node_name)
      .then((response) => {
        if (response?.error) {
          notify("Something went wrong!", "error");
          // reject();
        } else if (response.status === 200) {
          const newEventData = removeNodeByName(currentEventData, node_name)
          setTimeout(() => {
            dispatch(setEventData(newEventData))
          }, 200);
        }
        resolve();
      })
      .catch((error) => {
        // console.log("ERROR MESSAGE", error);
        notify(error?.message, "error");
      })
  });
};

export const getTreeHistory = (currentPage) => async (dispatch) => {
  return new Promise((resolve, reject) => {
    dispatch(setHistoryLoading(true));
    apiService.getTreeHistory({
      currentPage
    })
      .then((response) => {
        if (response?.error) {
          notify(response.error.code, "error");
          dispatch(setHistory(null));
        } else if (response?.status === 200) {
          dispatch(setTotalPages(response.data?.paginate.totalPages))
          dispatch(setHistory(response.data?.events));
          dispatch(setHistoryLoading(false));
        }
        resolve(response);
      }).catch(error => {
        console.log("ERROR MESSAGE", error);
        notify(error.message, "error");
      })
  })
};

export const treeHistoryRemove = () => async (dispatch, getState) => {
  return new Promise((resolve, reject) => {
    const state = getState();
    let deleteEventId = state.treeMap.selectedDeleteTreeId,
      selectedEventId = state.treeMap.selectedTreeId;
    apiService.deleteEvent({ deleteEventId })
      .then((response) => {
        if (response?.error) {
          notify(response.error.code, "error");
        } else if (response.status === 200) {
          let updatedHistory = state.treeMap.history.filter((el) => el._id !== deleteEventId),
            url = new URL(window.location.href);
          if (updatedHistory?.length > 0) {

            if (url.searchParams.get("eventId") && (selectedEventId == deleteEventId)) {
              url.searchParams.set("eventId", updatedHistory[0]._id)
              window.history.pushState(null, '', url.toString());
              dispatch(getEvent(updatedHistory[0]._id))
            }
          }
          else if (updatedHistory?.length == 0) {
            url.searchParams.delete("eventId")
            window.history.pushState(null, '', url.toString());
            dispatch(setSelectedTreeId(""));
            dispatch(setEventFullData(null));
            dispatch(setEventData(null))
          }
          dispatch(setHistory(updatedHistory));
          dispatch(
            setIsConfirmDeleteModalOpen({ type: "event", id: "", name: "" })
          );
          notify("Event Removed Successfully!", "success")
        }
        resolve();
      })
      .catch((error) => {
        notify(error.message, "error");
      })
  })
};

export const eventTitleUpdate = (treeHistoryId, title, employee_name) => async (dispatch, getState) => {
  return new Promise((resolve, reject) => {
    const state = getState();
    apiService
      .udpateEventTitle({
        treeHistoryId,
        title,
        employee_name
      })
      .then((response) => {
        if (response?.error) {
          notify(response.error.code, "error");
        }
        resolve();
      })
      .catch((error) => {
        notify(error.message, "error");
      });
  });
};

export const generatePlan = (node_name) => async (dispatch, getState) => {
  const selectedTreeId = getState().treeMap.selectedTreeId;
  dispatch(setSelectedTreeNode(node_name));
  dispatch(setPlanData(""));
  dispatch(setPlanLoading(true));
  dispatch(setVisibleRightPanel("Plans"));

  try {
    await apiService.generatePlan({
      key: selectedTreeId,
      node_name,
    });
  } catch (error) {
    dispatch(setPlanData(null));
    dispatch(setPlanLoading(false));
    console.error("Error generating plan:", error);
  }
};

export const getPlan = (nodeName) => (dispatch, getState) => {
  return new Promise((resolve, reject) => {
    const selectedTreeId = getState().treeMap.selectedTreeId;
    dispatch(setSelectedTreeNode(nodeName));
    dispatch(setVisibleRightPanel("Plans"));
    dispatch(setPlanLoading(true));

    apiService.getPlan({
      key: selectedTreeId,
      nodeName
    })
      .then((response) => {
        if (response?.error) {
          notify(response.error.code, "error");
        } else if (response?.status === 200) {
          dispatch(setPlanData(response.data?.result));
          dispatch(setPlanLoading(false));
        }
        resolve(response);
      }).catch(error => {
        console.log("ERROR MESSAGE", error);
        notify(error.message, "error");
      })
  })
};

export const updatePlan = (key, node_name, context) => async (dispatch) => {
  dispatch(setPlanData(""));
  try {
    await apiService.updatePlan({
      key,
      node_name,
      context
    });
  } catch (error) {
    dispatch(setPlanData(null));
    console.error("Error updating plan:", error);
  }
};

export const removePlan = (key, node_name) => (dispatch, getState) => {
  return new Promise((resolve, reject) => {
    const state = getState();
    apiService.removePlanRecord({
      key,
      node_name,
    }).then((response) => {
      if (response?.error) {
        notify(response.error.code, "error");
      } else if (response.status === 200) {
        notify("Plan Removed Successfully!", "success");
        dispatch(setPlanData(null));
        dispatch(setVisibleRightPanel("TreeHistory"));
        dispatch(
          setSelectedTreePlannedNodeNames(
            state.treeMap.selectedTreePlannedNodeNames.filter(
              (elm) => elm !== node_name
            )
          )
        );
        dispatch(setIsConfirmDeleteModalOpen({ type: "plan" }));
        document.getElementById(`spanNum${state.treeMap.selectedTreeNode.id}`)?.remove();
        dispatch(setSelectedTreeNode(null));
      }
      resolve();
    })
      .catch((error) => {
        notify(error.message, "error");
      })
  })
};

export const addShareLink = (label) => async (dispatch, getState) => {
  return new Promise((resolve, reject) => {
    const selectedEventId = getState().treeMap.selectedTreeId;
    const shareLinks = getState().treeMap.shareLinks;

    apiService.createAssessmentUrl(selectedEventId, label)
      .then((response) => {
        if (response?.error) {
          notify("Something went wrong!", "error");
          // reject();
        } else if (response.status === 200) {
          dispatch(setShareLinks([...shareLinks, label]))
          navigator.clipboard.writeText(
            `${window.location.origin}/share?eventID=${selectedEventId}&label=${label}`
          );
          notify("Event link copied to clipboard", "success");
        }
        resolve();
      })
      .catch((error) => {
        // console.log("ERROR MESSAGE", error);
        notify(error?.message, "error");
      })
  });
};

export const prioritizeNode = (node_name) => async (dispatch, getState) => {
  return new Promise((resolve, reject) => {
    const eventId = getState().treeMap.selectedTreeId;
    const importantNodes = getState().treeMap.importantNodes;

    apiService.prioritizeNode({ node_name, eventId })
      .then((response) => {
        if (response?.error) {
          notify("Something went wrong!", "error");
          // reject();
        } else if (response.status === 200) {
          dispatch(setImportantNodes([...importantNodes, node_name]))
        }
        resolve();
      })
      .catch((error) => {
        // console.log("ERROR MESSAGE", error);
        notify(error?.message, "error");
      })
  });
}

export const deprioritizeNode = (node_name) => async (dispatch, getState) => {
  return new Promise((resolve, reject) => {
    const eventId = getState().treeMap.selectedTreeId;
    const importantNodes = getState().treeMap.importantNodes;

    apiService.deprioritizeNode({ node_name, eventId })
      .then((response) => {
        if (response?.error) {
          notify("Something went wrong!", "error");
          // reject();
        } else if (response.status === 200) {
          dispatch(setImportantNodes(importantNodes.filter(el => el !== node_name)))
        }
        resolve();
      })
      .catch((error) => {
        // console.log("ERROR MESSAGE", error);
        notify(error?.message, "error");
      })
  });
}

export const getKPIs = (eventId) => async (dispatch) => {
  return new Promise((resolve, reject) => {
    dispatch(setLoading(true));
    apiService.getKPIs(eventId)
      .then((res) => {
        if (res?.response?.status === 400) {
          notify(res?.response?.data?.message, "error");
          // reject();
          dispatch(setKPIs(null))
        }
        else if (res.status === 200) {
          dispatch(setKPIs(res.data?.kpi))
        }
        resolve();
        dispatch(setLoading(false));
      })
      .catch((error) => {
        notify(error?.response?.data?.message, "error");
        dispatch(setLoading(false));
      })
  })
}

export const deleteSingleKPI = (eventId, kpiId) => async (dispatch) => {
  return new Promise((resolve, reject) => {
    apiService.deleteSingleKPI(eventId, kpiId)
      .then((res) => {
        if (res?.response?.status === 400) {
          notify(res?.response?.data?.message, "error");
          // reject();
        }
        else if (res.status === 200) {
          dispatch(setKPIs(res.data?.result))
        }
        resolve();
      })
      .catch((error) => {
        // console.log("ERROR MESSAGE", error);
        notify(error?.message, "error");
      })
  });
}

export const editSingleKPI = ({ eventId, kpiId, name, target, description, formula, pitfall, status, current }) => async (dispatch) => {
  return new Promise((resolve, reject) => {
    apiService.editSingleKPIParm({ eventId, kpiId, name, target, description, formula, pitfall, status, current })
      .then((res) => {
        if (res?.response?.status === 400) {
          notify(res?.response?.data?.message, "error");
          // reject();
        } else if (res.status === 200) {
          dispatch(setKPIs(res.data?.result))
        }
        resolve();
      })
      .catch((error) => {
        // console.log("ERROR MESSAGE", error);
        notify(error?.message, "error");
      })
  });
}

export const createKPIs = (eventId) => async (dispatch) => {
  return new Promise((resolve, reject) => {
    dispatch(setKPIsGenerating(true));
    dispatch(setKPIs(null));
    apiService.createKPIs(eventId)
      .then((res) => {
        console.log(res);
        if (res?.response?.status === 400) {
          notify(res?.response?.data?.message, "error");
          // reject();
        } else if (res.status === 201) {
          dispatch(setKPIs(res.data?.result))
        }
        resolve();
        dispatch(setKPIsGenerating(false));
      })
      .catch((error) => {
        // console.log("ERROR MESSAGE", error);
        notify(error?.message, "error");
        dispatch(setKPIsGenerating(false));
      })
  });
}

export const regenerateKPIs = (eventId) => async (dispatch) => {
  return new Promise((resolve, reject) => {
    dispatch(setKPIs(null));
    dispatch(setKPIsGenerating(true));
    apiService.regenerateKPIs(eventId)
      .then((response) => {
        if (response?.error) {
          notify("Something went wrong!", "error");
          // reject();
        } else if (response.status === 200) {

          dispatch(setKPIs(response.data?.result))
        }
        resolve();
        dispatch(setKPIsGenerating(false));
      })
      .catch((error) => {
        // console.log("ERROR MESSAGE", error);
        notify(error?.message, "error");
        dispatch(setKPIsGenerating(false));
      })
  });
}

export const deleteShareLink = (label) => async (dispatch, getState) => {
  return new Promise((resolve, reject) => {
    const selectedEventId = getState().treeMap.selectedTreeId;
    const shareLinks = getState().treeMap.shareLinks;

    apiService.deleteAssessmentUrl(selectedEventId, label)
      .then((response) => {
        if (response?.error) {
          notify("Something went wrong!", "error");
          // reject();
        } else if (response.status === 200) {
          dispatch(setShareLinks(shareLinks.filter(el => el != label)))
        }
        resolve();
      })
      .catch((error) => {
        // console.log("ERROR MESSAGE", error);
        notify(error?.message, "error");
      })
  });
};

export const notify = (title, type) => {
  let color = "#3a9c3b";
  if (type === "error") {
    color = "#d73f35";
  } else if (type === "info") {
    color = "#3498DB"
  }
  return toast(title, {
    position: "top-right",
    autoClose: type === "success" ? 1000 : 2000,
    hideProgressBar: true,
    closeOnClick: true,
    pauseOnHover: true,
    type,
    style: {
      background: color,
      fontFamily: "ClashDisplay-Medium",
      width: isMobile && "80vw",
      margin: isMobile ? "4vw" : 0,
      borderRadius: "4px",
    },
    theme: "colored",
  });
};
