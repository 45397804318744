import { Box } from "@mui/material";
import { ReactComponent as BrandLogo } from "../../assets/images/logo.svg";
import { Steps } from "./Steps";
import { useNavigate } from "react-router-dom";
import { useSelector } from "react-redux";

export const Sidebar = ({
  currentStepNumber,
  setCurrentStepNumber,
  setTreeInfoChanged,
}) => {
  const navigate = useNavigate();
  const userData = useSelector((state) => state.treeMap.userData);
  return (
    <Box
      height="100%"
      borderRight="solid 1px #e7e7e7"
      width="15%"
      minWidth="15%"
      maxWidth="240px"
      display="flex"
      flexDirection="column"
      alignItems="start"
      p="40px 20px"
    >
      <BrandLogo
        onClick={() =>
          navigate(
            userData?.judo_Application?.createdEvent
              ? "/"
              : "/?eventId=null&page=1"
          )
        }
        style={{
          height: 27,
          cursor: "pointer",
          alignSelf: "center",
          marginBottom: "30%",
        }}
      />
      <Steps
        currentStepNumber={currentStepNumber}
        setCurrentStepNumber={setCurrentStepNumber}
        setTreeInfoChanged={setTreeInfoChanged}
      />
    </Box>
  );
};
