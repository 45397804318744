import React, { useEffect } from "react";
import { Box, Button, Tooltip } from "@mui/material";
import { useDispatch, useSelector } from "react-redux";
import {
  generateEvent,
  setLastCompletedStep,
  setEventGenerating,
  setSelectedTreeId,
  setEventFullData,
} from "../../../reducers/treeMapSlice";
import { useTreeStream } from "../../../hooks/useTreeStream";
import IndentedList from "../../IndentedList/IndentedList";
import { getSocket } from "../../../socket";
import { ReactComponent as RightIcon } from "../../../assets/images/icons/downIcon.svg";

const styles = {
  footerBTN: {
    width: "180px",
    marginTop: "20px",
    height: "44px",
    color: "white",
    textTransform: "none",
    borderRadius: 2,
    fontSize: 15,
    fontFamily: "ClashDisplay-Medium",
  },
};

export const TreeStep = ({
  setImportantNotesChanged,
  setTreeInfoChanged,
  treeInfoChanged,
  setCurrentStepNumber,
}) => {
  const lastCompletedStep = useSelector(
    (state) => state.treeMap.lastCompletedStep
  );
  const contextStepInfo = useSelector((state) => state.treeMap.contextStepInfo);
  const importantNodes = useSelector((state) => state.treeMap.importantNodes);
  const eventData = useSelector((state) => state.treeMap.eventData);
  const userData = useSelector((state) => state.treeMap.userData);
  const dispatch = useDispatch();
  const { format_storeChunk } = useTreeStream();
  const socket = getSocket(userData._id);

  const onConnect_TreeStep = () => {
    dispatch(generateEvent({ ...contextStepInfo }));
  };

  const onStreamStarted_TreeStep = (res) => {
    dispatch(setSelectedTreeId(res.eventId));
    dispatch(
      setEventFullData({
        ...contextStepInfo,
        title: contextStepInfo.job_title || contextStepInfo.event,
        _id: res.eventId,
      })
    );
  };

  const onReciveStreamChunk_TreeStep = (chunk) => {
    format_storeChunk(chunk);
  };

  const onStreamCompleted_TreeStep = () => {
    dispatch(setEventGenerating(false));
    socket.off("connect", onConnect_TreeStep);
    socket.off("streamStarted", onStreamStarted_TreeStep);
    socket.off("streamChunk", onReciveStreamChunk_TreeStep);
    socket.off("streamCompleted", onStreamCompleted_TreeStep);
    socket.disconnect();
  };

  useEffect(() => {
    if (treeInfoChanged) {
      socket.on("connect", onConnect_TreeStep);
      socket.on("streamStarted", onStreamStarted_TreeStep);
      socket.on("streamChunk", onReciveStreamChunk_TreeStep);
      socket.on("streamCompleted", onStreamCompleted_TreeStep);
      socket.connect();
      setTreeInfoChanged(false);
    }
  }, [treeInfoChanged]);

  return (
    <Box sx={{ height: "100%", p: "20px", boxSizing: "border-box" }}>
      <Box
        sx={{
          height: "calc(100% - 70px)",
          width: "100%",
          backgroundColor: "white",
          borderRadius: 2,
          border: "1px solid #c1cdd5",
          boxSizing: "border-box",
          overflow: "auto",
        }}
      >
        <IndentedList
          isGuideTree
          importantNodes={importantNodes}
          setImportantNotesChanged={setImportantNotesChanged}
          data={eventData?.Pitfalls ?? eventData?.children}
          dispatch={dispatch}
        />
      </Box>
      <Box
        sx={{ display: "flex", justifyContent: "space-between", flexShrink: 0 }}
      >
        <Button
          variant="contained"
          onClick={() => setCurrentStepNumber(1)}
          sx={{
            ...styles.footerBTN,
            backgroundColor: "#1D77B7",
            "&:hover": {
              backgroundColor: "#1D77B7",
              boxShadow: "none",
            },
          }}
          startIcon={
            <RightIcon
              style={{
                width: 15,
                height: 15,
                transform: "rotate(90deg)",
                fill: "white",
              }}
            />
          }
        >
          Back
        </Button>
        <Tooltip
          arrow
          enterDelay={500}
          title={
            importantNodes.length === 0
              ? "You should highlight at least 1 node to go to next step"
              : ""
          }
        >
          <span>
            <Button
              variant="contained"
              type="submit"
              disabled={importantNodes.length === 0}
              onClick={() => {
                if (!lastCompletedStep < 2 && importantNodes.length > 0) {
                  dispatch(setLastCompletedStep(2));
                }
                setCurrentStepNumber(3);
              }}
              sx={{
                ...styles.footerBTN,
                backgroundColor: "#1D77B7",
                ml: "3%",
                "&:hover": {
                  backgroundColor: "#1D77B7",
                  boxShadow: "none",
                },
              }}
              endIcon={
                <RightIcon
                  style={{
                    width: 15,
                    height: 15,
                    fill: importantNodes.length === 0 ? "gray" : "white",
                    transform: "rotate(270deg)",
                  }}
                />
              }
            >
              Next
            </Button>
          </span>
        </Tooltip>
      </Box>
    </Box>
  );
};
