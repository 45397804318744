import {
  Box,
  Button,
  Menu,
  MenuItem,
  Skeleton,
  Tooltip,
  Pagination,
  Accordion,
  AccordionSummary,
  AccordionDetails,
  styled,
} from "@mui/material";
import ArrowForwardIosSharpIcon from "@mui/icons-material/ArrowForwardIosSharp";
import { useState, useEffect } from "react";
import { ReactComponent as Arrow } from "../../assets/images/icons/downIcon.svg";
import { ReactComponent as BrandLogo } from "../../assets/images/logo.svg";
import roleIcon from "../../assets/images/icons/role.svg";
import situationIcon from "../../assets/images/icons/situation.svg";
import lineIcon from "../../assets/images/icons/lines.svg";
import feedback from "../../assets/images/icons/feedback.svg";
import AddCircleIcon from "@mui/icons-material/AddCircle";
import { useNavigate } from "react-router-dom";
import { useSelector, useDispatch } from "react-redux";
import { gotoCustomerPortal, signOut } from "../../services/api-service";
import { useSearchParams, NavLink } from "react-router-dom";
import {
  setUserDataLoading,
  notify,
  getEvent,
  getKPIs,
  setSelectedUpdateTreeId,
  getTreeHistory,
} from "../../reducers/treeMapSlice";
import { getAnalytics, logEvent } from "firebase/analytics";
import "./SideBar.css";

const SideBar = ({ setShowManageEvent, setIsKPIView }) => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [anchorEl, setAnchorEl] = useState(null);
  const analytics = getAnalytics();
  let [searchParams, setSearchParams] = useSearchParams();
  const eventHistory = useSelector((state) => state.treeMap.history);
  const eventLoading = useSelector((state) => state.treeMap.loading);
  const eventGenerating = useSelector((state) => state.treeMap.eventGenerating);
  const userData = useSelector((state) => state.treeMap.userData);
  const selectedEventId = useSelector((state) => state.treeMap.selectedTreeId);
  const totalPages = useSelector((state) => state.treeMap.totalPages);
  const isEventHistoryLoading = useSelector(
    (state) => state.treeMap.isTreeHistoryLoading
  );
  const kpis = useSelector((state) => state.treeMap.KPIs);
  const eventId = searchParams.get("eventId"),
    pageNumber = parseInt(searchParams.get("page")) || 1;
  const open = Boolean(anchorEl);

  const AppAccordion = styled((props) => (
    <Accordion
      disableGutters
      elevation={0}
      {...props}
      defaultExpanded={props.id === selectedEventId}
    />
  ))(() => ({
    margin: "10px",
    "&:not(:last-child)": {
      borderBottom: 0,
    },
    "&:first-of-type": {
      marginTop: 0,
    },
    borderRadius: "7px",
    "&::before": {
      display: "none",
    },
    backgroundColor: "#f7f7f7",
    "&:hover": {
      backgroundColor: "white",
    },
  }));

  const AppAccordionSummary = styled((props) => (
    <AccordionSummary
      {...props}
      expandIcon={<ArrowForwardIosSharpIcon sx={{ fontSize: "0.9rem" }} />}
    />
  ))(({ theme, id }) => ({
    borderRadius: "7px",
    display: "flex",
    alignItems: "center",
    border: id === selectedEventId && "1px solid #3b75cd9c",
    backgroundColor: id === selectedEventId && "white",
    "& .MuiAccordionSummary-expandIconWrapper.Mui-expanded": {
      transform: "rotate(90deg)",
    },
    "& .MuiAccordionSummary-content": {
      marginLeft: theme.spacing(1),
    },
  }));

  const AppAccordionDetails = styled(AccordionDetails)(({ theme }) => ({
    display: "flex",
    marginLeft: "18px",
    padding: theme.spacing(2),
    paddingTop: "0px",
  }));

  const HeaderMenuIcon = ({ label, onClick, ...props }) => (
    <MenuItem sx={{ fontSize: "14px" }} onClick={onClick} {...props}>
      {label}
    </MenuItem>
  );

  const onAccountClick = async () => {
    dispatch(setUserDataLoading(true));
    const response = await gotoCustomerPortal();
    if (response?.error) {
      notify(response.error.code, "error");
    } else {
      if (response?.url) {
        window.location.href = response?.url;
      }
    }
    setTimeout(() => {
      dispatch(setUserDataLoading(false));
    }, 1000);
  };

  const selectEvent = (key) => {
    setShowManageEvent(null);
    dispatch(setSelectedUpdateTreeId(""));
    if (!eventLoading && !eventGenerating) {
      logEvent(analytics, `Event Clicked From Event History!`, {
        user: userData._id,
      });
      dispatch(getEvent(key));
      setSearchParams({
        ...Object.fromEntries(searchParams),
        eventId: key,
      });
    }
  };

  useEffect(() => {
    if (pageNumber != 1) {
      dispatch(getTreeHistory(pageNumber));
    } else dispatch(getTreeHistory(1));
  }, [pageNumber]);

  useEffect(() => {
    if (searchParams.has("code")) {
      const state = JSON.parse(searchParams.get("state"));
      setSearchParams({
        ...Object.fromEntries(searchParams),
        eventId: state.eventId,
        page: state.page,
      });
      selectEvent(state.eventId);
    } else {
      if (eventHistory.length > 0 && eventId && pageNumber) {
        if (!selectedEventId) {
          selectEvent(eventId);
        } else {
          selectEvent(selectedEventId);
        }
      } else if (eventHistory.length > 0 && (!eventId || !pageNumber)) {
        selectEvent(eventHistory[0]._id);
        setSearchParams({
          ...Object.fromEntries(searchParams),
          eventId: eventHistory[0]._id,
          page: 1,
        });
      }
    }
  }, [selectedEventId, eventHistory]);

  const LoadingCard = ({ index }) => (
    <Box key={index} className="loadingCardContainer">
      <Skeleton
        variant="text"
        width="90%"
        height="20px"
        sx={{ backgroundColor: "#e7e7e7", mt: "10px" }}
        animation="wave"
      />
      <Skeleton
        variant="rectangular"
        width="90%"
        height="30px"
        sx={{ backgroundColor: "#e7e7e7", mt: 1 }}
        animation="wave"
      />
    </Box>
  );

  const EventCard = ({ event, i }) => {
    return (
      <AppAccordion key={i} id={event._id}>
        <AppAccordionSummary aria-controls="panel1a-content" id={event._id}>
          <img
            src={event.requestType === 1 ? roleIcon : situationIcon}
            alt="Logo"
            width={event.requestType === 1 ? "25" : "16"}
            height={event.requestType === 1 ? "25" : "16"}
            style={{
              cursor: "pointer",
              marginRight: event.requestType === 1 ? "0px" : "7px",
              marginLeft: event.requestType === 1 ? "0px" : "2px",
            }}
          />
          <Box className="card-detail">
            <Tooltip
              enterDelay={1000}
              enterNextDelay={1000}
              title={
                event?.requestType == 1 ? event.employee_name : event.event_name
              }
              arrow
            >
              <Box>
                {event?.requestType == 1
                  ? event.employee_name
                  : event.event_name}
              </Box>
            </Tooltip>
          </Box>
        </AppAccordionSummary>
        <AppAccordionDetails>
          <img src={lineIcon} alt="Line" />
          <Box className="event-type-container">
            <Box
              className="event-type-title"
              mt="15px"
              onClick={() => {
                kpis?.eventId !== event._id &&
                  dispatch(getKPIs(event._id));
                selectEvent(event._id);
                setIsKPIView(true);
              }}
            >
              KPIs
            </Box>
            <Box
              className="event-type-title"
              mt="20px"
              onClick={() => {
                selectEvent(event._id);
                setIsKPIView(false);
              }}
            >
              Tree
            </Box>
          </Box>
        </AppAccordionDetails>
      </AppAccordion>
    );
  };

  return (
    <Box className="sidebar">
      <Box display="flex" width="100%" justifyContent="space-between">
        <BrandLogo
          style={{
            width: 120,
            height: 27,
            marginBottom: "20%",
          }}
        />
        <Box style={{ display: "flex", alignItems: "start" }}>
          <Button
            className="sidebar-user-button"
            aria-controls={open ? "basic-menu" : undefined}
            aria-haspopup="true"
            aria-expanded={open ? "true" : undefined}
            onClick={(event) => setAnchorEl(event.currentTarget)}
            startIcon={
              <div className="username">
                {userData?.email ? userData?.email.charAt(0) : null}
              </div>
            }
          ></Button>
          <Menu
            anchorEl={anchorEl}
            open={open}
            onClose={() => setAnchorEl(null)}
            elevation={0}
            MenuListProps={{
              "aria-labelledby": "sidebar-user-button",
              sx: {
                minWidth: "100px",
                maxWidth: "100px",
                borderRadius: "3px",
                margin: "2px 0px 2px",
                border: "1px solid #dde0e4",
                boxShadow: "2px 1px 2px rgba(0, 0, 0, 0.1)",
                WebkitBoxShadow: "2px 1px 2px rgba(0, 0, 0, 0.1)",
              },
            }}
          >
            <HeaderMenuIcon
              label="Privacy"
              onClick={() =>
                window.open(
                  "https://www.antethink.com/privacy-policy",
                  "_blank"
                )
              }
            />
            <HeaderMenuIcon label="Account" onClick={() => onAccountClick()} />
            <HeaderMenuIcon label="Sign-out" onClick={() => signOut()} />
          </Menu>
          <Box>
            <Arrow
              style={{
                transform: open && "rotate(180deg)",
                transition: "all 0.2s ease-in-out",
                marginTop: "10px",
              }}
            />
          </Box>
        </Box>
      </Box>
      <Box
        className="add-tree"
        onClick={() => navigate("/create-event", { state: { from: "header" } })}
      >
        <Box className="new-title">Add New Event</Box>
        <AddCircleIcon
          color="primary"
          style={{ marginLeft: "5px" }}
        ></AddCircleIcon>
      </Box>
      <Box
        className="sidebar-inner"
        sx={{
          height: `calc(100% - ${totalPages > 1 ? "160px" : "120px"})`,
        }}
      >
        {isEventHistoryLoading ? (
          Array(4)
            .fill()
            .map((_, index) => <LoadingCard key={index} index={index} />)
        ) : eventHistory?.length ? (
          eventHistory?.map((event, i) => (
            <EventCard key={i} event={event} i={i} />
          ))
        ) : (
          <Box className="event-sidebar-sub-title"> No Results </Box>
        )}
      </Box>
      {totalPages > 1 && (
        <Pagination
          sx={{
            alignSelf: "center",
            marginTop: 15,
            position: "absolute",
            bottom: "10.5%",
          }}
          page={pageNumber || 1}
          size="small"
          shape="rounded"
          count={totalPages}
          color="primary"
          onChange={(e, val) =>
            setSearchParams({ ...Object.fromEntries(searchParams), page: val })
          }
        />
      )}
      <NavLink
        to="https://antethink.nolt.io/newest"
        className="feedback"
        target="_blank"
      >
        <img
          src={feedback}
          alt="Feedback"
          width="16"
          height="16"
          style={{
            padding: 0,
            marginRight: "7px",
            cursor: "pointer",
          }}
        />
        Feedback
      </NavLink>
    </Box>
  );
};

export default SideBar;
