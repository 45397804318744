import {
  Box,
  Button,
  Select,
  Typography,
  MenuItem,
  TextField,
} from "@mui/material";
import React, { useRef, useEffect, useState } from "react";
import { IsMobile } from "../../utils/deviceType";
import { useForm, Controller } from "react-hook-form";
import {
  setSelectedUpdateTreeId,
  setSelectedTreePlannedNodeNames,
  eventTitleUpdate,
  generateEvent,
  setSelectedTreeId,
  setEventGenerating,
  setLoading,
  setHistory,
  setVisualizationType,
  setEventFullData,
  setEventData,
} from "../../reducers/treeMapSlice";
import { useDispatch, useSelector } from "react-redux";
import { useTreeStream } from "../../hooks/useTreeStream";
import { getSocket } from "../../socket";

const styles = {
  mainContainer: {
    width: "100%",
    height: "100%",
    boxSizing: "border-box",
    paddingTop: "6%",
  },
  headerTxt: {
    fontSize: "28px",
    fontFamily: "ClashDisplay-Medium",
  },
  container: {
    width: "85%",
    margin: "0 auto",
    padding: "30px",
    borderRadius: "5px",
    border: "0.5px solid #c1cdd5",
    boxSizing: "border-box",
    boxShadow: "0px 4px 6px rgba(0,0,0,0.1)",
    backgroundColor: "white",
  },
  footer: {
    width: "85%",
    margin: "0 auto",
    marginTop: "25px",
    display: "flex",
    justifyContent: "space-between",
  },
  footerBTN: {
    width: "48.5%",
    height: "44px",
    color: "white",
    textTransform: "none",
    borderRadius: 2,
    fontSize: 15,
    fontFamily: "ClashDisplay-Medium",
  },
  footerText: {
    display: "inline-block",
    fontSize: "14px",
    fontFamily: "ClashDisplay-Regular",
  },
};

const ManageEvent = ({ showManageEvent, setShowManageEvent }) => {
  const {
    control,
    handleSubmit,
    setError,
    getValues,
    setValue,
    reset,
    formState: { errors },
  } = useForm();
  const dispatch = useDispatch();
  const isMobile = IsMobile();
  const isRoleEventRef = useRef(showManageEvent === "role");
  const [initialValues, setInitialValues] = useState({});
  const selectedUpdateTreeId = useSelector(
    (state) => state.treeMap.selectedUpdateTreeId
  );
  const eventsHistory = useSelector((state) => state.treeMap.history);
  const updateEvent = eventsHistory?.find(
    (el) => el._id === selectedUpdateTreeId
  );
  const isRoleEvent = showManageEvent === "role";
  const { format_storeChunk } = useTreeStream();
  const userData = useSelector((state) => state.treeMap.userData);
  const socket = getSocket(userData._id);

  useEffect(() => {
    socket.on("connect", onConnect_ManageEvent);
    socket.on("streamStarted", onStreamStarted_ManageEvent);
    socket.on("streamChunk", onReciveStreamChunk_ManageEvent);
    socket.on("streamCompleted", onStreamCompleted_ManageEvent);
  }, []);

  const onConnect_ManageEvent = () => {
    dispatch(setEventData(""));
    dispatch(
      generateEvent({
        key: selectedUpdateTreeId,
        ...getValues(),
        requestType: isRoleEventRef.current ? 1 : 2,
      })
    );
  };

  const onStreamStarted_ManageEvent = (res) => {
    const { event, job_title, context, main_branches } = getValues();
    const requestType = isRoleEventRef.current ? 1 : 2;

    const newEvent = {
      _id: res.eventId,
      title: requestType === 1 ? job_title : event,
      context,
      requestType,
      branches: { main_branches, sub_branches: 4, specific_actions: 3 },
      ...(requestType === 1
        ? { employee_name: event, job_title }
        : { event_name: event }),
    };

    dispatch(setEventFullData(newEvent));
    dispatch(setVisualizationType(1));
    dispatch(
      setHistory(
        selectedUpdateTreeId
          ? eventsHistory.map((el) =>
              el._id === selectedUpdateTreeId ? newEvent : el
            )
          : [newEvent, ...eventsHistory]
      )
    );
    dispatch(setLoading(false));
    dispatch(setSelectedTreeId(res.eventId));

    if (selectedUpdateTreeId) {
      dispatch(setSelectedUpdateTreeId(""));
      dispatch(setSelectedTreePlannedNodeNames([]));
    }

    const url = new URL(window.location.href);
    url.searchParams.set("eventId", res.eventId);
    window.history.pushState(null, "", url.toString());

    setShowManageEvent(false);
  };

  const onReciveStreamChunk_ManageEvent = (chunk) => {
    format_storeChunk(chunk);
  };

  const onStreamCompleted_ManageEvent = () => {
    dispatch(setEventGenerating(false));
    socket.off("connect", onConnect_ManageEvent);
    socket.off("streamStarted", onStreamStarted_ManageEvent);
    socket.off("streamChunk", onReciveStreamChunk_ManageEvent);
    socket.off("streamCompleted", onStreamCompleted_ManageEvent);
    socket.disconnect();
  };

  const onSubmit = async (data) => {
    if (!selectedUpdateTreeId) {
      socket.connect();
      return;
    }
    const hasChanges = Object.keys(data).some(
      (key) => data[key] !== updateEvent[key]
    );
    if (!hasChanges) return;

    const isOnlyJobTitleEdited =
      isRoleEvent &&
      ["event", "context", "main_branches"].every(
        (key) => data[key] === initialValues[key]
      ) &&
      data.job_title !== initialValues.job_title;

    if (isOnlyJobTitleEdited) {
      dispatch(
        eventTitleUpdate(
          selectedUpdateTreeId,
          data.job_title,
          initialValues.event
        )
      );
    } else {
      socket.connect();
    }
  };

  useEffect(() => {
    if (selectedUpdateTreeId) {
      const newValues = {
        event: isRoleEvent ? updateEvent.employee_name : updateEvent.event_name,
        job_title: updateEvent?.job_title,
        context: updateEvent.context,
        main_branches: updateEvent?.branches?.main_branches,
      };
      Object.entries(newValues).forEach(([key, value]) => setValue(key, value));
      setInitialValues(newValues);
    } else {
      reset();
    }
  }, [selectedUpdateTreeId]);

  useEffect(() => {
    isRoleEventRef.current = showManageEvent === "role";
  }, [showManageEvent]);

  const onCancel = () => {
    setShowManageEvent(false);
    selectedUpdateTreeId && dispatch(setSelectedUpdateTreeId(""));
  };

  const handleFieldChange = (fieldName) => {
    setError(fieldName, ""); // Clear errors on field change
  };

  return (
    <Box sx={styles.mainContainer}>
      <form onSubmit={handleSubmit(onSubmit)}>
        <Box sx={styles.container}>
          <Typography sx={styles.headerTxt}>
            Generate the Inverted List
          </Typography>
          <Controller
            control={control}
            name="main_branches"
            rules={{
              required: "Please fill this field",
            }}
            defaultValue={3}
            render={({ field }) => (
              <Box
                sx={{
                  display: "flex",
                  alignItems: "center",
                  mt: 4,
                  ml: "14px",
                }}
              >
                <Typography
                  sx={{ fontSize: "16px", fontFamily: "ClashDisplay-Regular" }}
                >
                  Select number of main branches:
                </Typography>
                <Select
                  {...field}
                  SelectDisplayProps={{
                    style: {
                      padding: "2px 22px 2px 8px",
                    },
                  }}
                  sx={{
                    ml: 1,
                    ".MuiSvgIcon-root": {
                      right: 2,
                    },
                    boxShadow: "none",
                    ".MuiOutlinedInput-notchedOutline": {
                      border: "1px solid #ccc",
                    },
                    "&.MuiOutlinedInput-root:hover .MuiOutlinedInput-notchedOutline, &.MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline":
                      {
                        border: "1px solid gray",
                      },
                  }}
                  inputProps={{ "aria-label": "Without label" }}
                >
                  {[3, 4, 5, 6].map((el, i) => (
                    <MenuItem key={i} value={el}>
                      {el}
                    </MenuItem>
                  ))}
                </Select>
              </Box>
            )}
          />
          <Controller
            control={control}
            name="event"
            rules={{
              required: "Please fill this field",
            }}
            defaultValue=""
            render={({ field }) => (
              <TextField
                sx={{
                  marginTop: 2,
                  "& fieldset": { border: !Boolean(errors.event) && "none" },
                }}
                inputProps={{
                  style: {
                    fontFamily: "ClashDisplay-Regular",
                    fontSize: isMobile ? 12 : 16,
                  },
                }}
                fullWidth
                placeholder={isRoleEvent ? "Name" : "Event"}
                onChange={() => {
                  handleFieldChange("event");
                }}
                {...field}
                helperText={errors.event?.message}
                error={Boolean(errors.event)}
              />
            )}
          />
          {isRoleEvent && (
            <Controller
              control={control}
              name="job_title"
              defaultValue=""
              rules={{
                required: "Please fill this field",
              }}
              render={({ field }) => (
                <TextField
                  sx={{
                    "& fieldset": {
                      border: !Boolean(errors.job_title) && "none",
                    },
                  }}
                  inputProps={{
                    style: {
                      fontFamily: "ClashDisplay-Regular",
                      fontSize: isMobile ? 12 : 16,
                    },
                  }}
                  fullWidth
                  placeholder={"Title"}
                  onChange={() => {
                    handleFieldChange("job_title");
                  }}
                  {...field}
                  helperText={errors.job_title?.message}
                  error={Boolean(errors.job_title)}
                />
              )}
            />
          )}
          <Controller
            control={control}
            name="context"
            rules={{
              required: "Please fill this field",
            }}
            defaultValue=""
            render={({ field }) => (
              <TextField
                sx={{
                  "& fieldset": { border: !Boolean(errors.context) && "none" },
                }}
                inputProps={{
                  style: {
                    fontFamily: "ClashDisplay-Regular",
                    fontSize: isMobile ? 12 : 16,
                  },
                }}
                fullWidth
                placeholder="Context"
                multiline
                minRows={4}
                maxRows={6}
                onChange={() => {
                  handleFieldChange("context");
                }}
                {...field}
                helperText={errors.context?.message}
                error={Boolean(errors.context)}
              />
            )}
          />
        </Box>
        <Box sx={styles.footer}>
          <Typography
            sx={{
              ...styles.footerText,
              maxWidth: "45%",
              mr: 4,
            }}
          >
            Need to create multiple events?{" "}
            <Typography
              component={"span"}
              sx={{
                ...styles.footerText,
                cursor: "pointer",
                color: "#006bd6",
              }}
            >
              Upload xslx file!
            </Typography>
          </Typography>
          <Box sx={{ width: "max(46%, 220px)", flexShrink: 0 }}>
            <Button
              variant="contained"
              onClick={onCancel}
              sx={{
                ...styles.footerBTN,
                backgroundColor: "rgb(244, 67, 54)",
                "&:hover": {
                  backgroundColor: "rgb(244, 67, 54)",
                  boxShadow: "none",
                },
              }}
            >
              Cancel
            </Button>
            <Button
              variant="contained"
              type="submit"
              onClick={() => console.log()}
              sx={{
                ...styles.footerBTN,
                backgroundColor: "#1D77B7",
                ml: "3%",
                "&:hover": {
                  backgroundColor: "#1D77B7",
                  boxShadow: "none",
                },
              }}
            >
              Generate
            </Button>
          </Box>
        </Box>
      </form>
    </Box>
  );
};

export default ManageEvent;
