import React, { useRef, useState, useEffect } from "react";
import { TableRow } from "./TableRow";
import { Tooltip, IconButton, Button } from "@mui/material";
import { ReactComponent as CollapseIcon } from "../../assets/images/icons/collapse.svg";
import { ReactComponent as ExpandIcon } from "../../assets/images/icons/expand.svg";
// import ShareLinksModal from "./ShareLinksModal";
import { IsMobile } from "../../utils/deviceType";
import {
  generateNewBranch,
  setEventGenerating,
  setSelectedUpdateTreeId,
  setIsConfirmDeleteModalOpen,
} from "../../reducers/treeMapSlice";
import TextEntryModal from "../TextEntryModal/TextEntryModal";
import { getSocket } from "../../socket";
import { useTreeStream } from "../../hooks/useTreeStream";

const IndentedList = ({
  planGenerating,
  isAssesmentLink,
  isGuideTree,
  importantNodes,
  setImportantNotesChanged,
  data,
  eventData,
  // shareLinks,
  userData,
  selectedTreeNode,
  selectedTreePlannedNodeNames,
  eventRatings,
  // originalEventRatings,
  // eventGenerating,
  dispatch,
  setSidebarOpened,
  setShowManageEvent,
}) => {
  const [collapseAll, setCollapseAll] = useState("finished");
  const [lastCollapseState, setLastCollapseState] = useState("fully_expanded");
  // const [EventGenerating, setEventGenerating] = useState(eventGenerating);
  const [EventRatings, setEventRatings] = useState([]);
  const [shareLinksOpened, setShareLinksOpened] = useState(false);
  const [addBranch, setAddBranch] = useState(false);
  // const indetedListBottom = useRef(null);
  const branchContextRef = useRef("");
  const isMobile = IsMobile();
  const socket = getSocket(userData?._id);
  const { addNewBranchToEventData } = useTreeStream();

  // useEffect(() => {
  //   if (EventGenerating) {
  //     indetedListBottom?.current?.scrollIntoView({
  //       behavior: "smooth",
  //       block: "end",
  //     });
  //   }
  // }, [data]);

  // useEffect(() => {
  //   setEventGenerating(eventGenerating);
  // }, [eventGenerating]);

  const toggleOpenConfirmationModal = (eventId, eventName) => {
    dispatch(
      setIsConfirmDeleteModalOpen({
        type: "event",
        id: eventId,
        name: eventName,
      })
    );
  };

  const onConnect_AddBranch = () => {
    dispatch(
      generateNewBranch({
        key: eventData._id,
        context: branchContextRef.current,
      })
    );
  };

  const onReciveStreamChunk_AddBranch = (chunk) => {
    addNewBranchToEventData(chunk);
  };

  const onStreamCompleted_AddBranch = () => {
    dispatch(setEventGenerating(false));
    socket.off("connect", onConnect_AddBranch);
    socket.off("streamChunk", onReciveStreamChunk_AddBranch);
    socket.off("streamCompleted", onStreamCompleted_AddBranch);
    socket.disconnect();
  };

  useEffect(() => {
    setEventRatings(eventRatings);
  }, [eventRatings]);

  // const handleEventRatings = (operation, newRating) => {
  //   switch (operation) {
  //     case "create":
  //       setEventRatings([...EventRatings, newRating]);
  //       break;
  //     case "edit":
  //       setEventRatings(
  //         EventRatings.map((el) => (el._id === newRating._id ? newRating : el))
  //       );
  //       break;
  //     case "delete":
  //       setEventRatings(EventRatings.filter((el) => el._id !== newRating._id));
  //       break;
  //   }
  // };

  const handleAddBranch = (context) => {
    branchContextRef.current = context;
    socket.on("connect", onConnect_AddBranch);
    socket.on("streamChunk", onReciveStreamChunk_AddBranch);
    socket.on("streamCompleted", onStreamCompleted_AddBranch);
    socket.connect();
  };

  return (
    <div style={{ width: "100%", padding: "7px", boxSizing: "border-box" }}>
      <div className="indentedListHeader">
        <div style={{ display: "flex" }}>
          {!isAssesmentLink && (
            <>
              <Button
                onClick={() => setAddBranch(true)}
                variant="outline"
                sx={{
                  backgroundColor: "#eeeeee",
                  fontFamily: "ClashDisplay-Regular",
                  color: "#585858",
                  textTransform: "capitalize",
                  borderRadius: "5px",
                  p: "1.5px 8px",
                  ml: "5px",
                }}
              >
                Add branch
              </Button>
              {!isGuideTree && (
                <>
                  <Button
                    onClick={() => {
                      setShowManageEvent(
                        eventData.requestType === 1 ? "role" : "situation"
                      );
                      dispatch(setSelectedUpdateTreeId(eventData._id));
                    }}
                    variant="outline"
                    sx={{
                      backgroundColor: "#eeeeee",
                      fontFamily: "ClashDisplay-Regular",
                      color: "#585858",
                      textTransform: "capitalize",
                      borderRadius: "5px",
                      p: "1.5px 8px",
                      ml: "5px",
                    }}
                  >
                    Edit
                  </Button>
                  <Button
                    onClick={() =>
                      toggleOpenConfirmationModal(
                        eventData._id,
                        eventData.title
                      )
                    }
                    variant="outline"
                    sx={{
                      backgroundColor: "#eeeeee",
                      fontFamily: "ClashDisplay-Regular",
                      color: "#585858",
                      textTransform: "capitalize",
                      borderRadius: "5px",
                      p: "1.5px 8px",
                      ml: "5px",
                    }}
                  >
                    Delete
                  </Button>
                </>
              )}
            </>
          )}

          {/* {!isAssesmentLink && !isGuideTree && shareLinks?.length > 0 && (
            <Button
              onClick={() => setShareLinksOpened(true)}
              variant="outline"
              sx={{
                backgroundColor: "#eeeeee",
                fontFamily: "ClashDisplay-Regular",
                color: "#585858",
                textTransform: "capitalize",
                borderRadius: "5px",
                p: "1.5px 8px",
                ml: "5px",
              }}
            >
              View Share Links
            </Button>
          )} */}
        </div>
        <div
          style={{
            backgroundColor: "rgba(239, 239, 239, 1)",
            padding: 4,
            borderRadius: 4,
          }}
        >
          <Tooltip title="Expand All" arrow>
            <IconButton
              onClick={() => {
                setCollapseAll("fully_expanded");
                setLastCollapseState("fully_expanded");
              }}
              sx={{
                padding: "0px",
                margin: "0px 5px",
                backgroundColor: "transparent",
              }}
            >
              <ExpandIcon
                width={isMobile ? 18 : 14}
                height={isMobile ? 18 : 14}
              />
            </IconButton>
          </Tooltip>
          <Tooltip title="Collapse" arrow>
            <IconButton
              onClick={() => {
                if (lastCollapseState === "fully_expanded") {
                  setCollapseAll("expanded");
                  setLastCollapseState("expanded");
                } else {
                  setCollapseAll("collapsed");
                  setLastCollapseState("collapsed");
                }
              }}
              sx={{
                padding: "0px",
                margin: "0px 5px",
                backgroundColor: "transparent",
              }}
            >
              <CollapseIcon
                width={isMobile ? 18 : 14}
                height={isMobile ? 18 : 14}
              />
            </IconButton>
          </Tooltip>
        </div>
      </div>
      <div
        // ref={indetedListBottom}
        id="currentList"
        style={{ width: "100%", paddingBottom: 20 }}
      >
        {data?.map((row, index) => (
          <TableRow
            planGenerating={planGenerating}
            isAssesmentLink={isAssesmentLink}
            isGuideTree={isGuideTree}
            importantNodes={importantNodes}
            setImportantNotesChanged={setImportantNotesChanged}
            rowData={row}
            firstChild
            key={row.name + "-" + index}
            collapseAll={collapseAll}
            setCollapseAll={setCollapseAll}
            userData={userData}
            selectedTreeNode={selectedTreeNode}
            selectedTreePlannedNodeNames={selectedTreePlannedNodeNames}
            eventRatings={EventRatings}
            // originalEventRatings={originalEventRatings}
            // handleEventRatings={handleEventRatings}
            dispatch={dispatch}
            setSidebarOpened={setSidebarOpened}
          />
        ))}
      </div>
      {/* {!isAssesmentLink &&
        !isGuideTree &&
        shareLinks?.length > 0 &&
        shareLinksOpened && (
          <ShareLinksModal
            eventId={eventId}
            shareLinks={shareLinks}
            open={shareLinksOpened}
            setOpen={setShareLinksOpened}
            dispatch={dispatch}
          />
        )} */}
      {!isAssesmentLink && addBranch && (
        <TextEntryModal
          header="Add new branch"
          inputPlaceholder="Enter context"
          buttonText="Add Branch"
          textEntryModalOpened={addBranch}
          setTextEntryModalOpened={setAddBranch}
          action={handleAddBranch}
        />
      )}
    </div>
  );
};

export default IndentedList;
